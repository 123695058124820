import React, { useState } from "react";
import { Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import "./index.css";

const SettingsItem = (item: SettingsItem) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div className="SettingsItem">
			<div className="SettingsItem--toggle" onClick={toggle}>
				{item.button || (
					<Button ghost>
						<SettingOutlined style={{ fontSize: "20px" }} />
					</Button>
				)}
			</div>
			<div className={`SettingsItem--content ${isOpen ? "isOpen" : ""}`}>
				{item.content}
			</div>
		</div>
	);
};
export default SettingsItem;

import { useState, useEffect, Dispatch, SetStateAction } from "react";

// function useLocState<S = undefined>(): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

export const useLocState = <S extends unknown>(
	defVal: S,
	name: string
): [S, Dispatch<SetStateAction<S>>] => {
	const localName = "useLocState_" + name;
	const savedState = localStorage.getItem(localName);

	const [state, setState] = useState(
		savedState ? JSON.parse(savedState) : defVal
	);
	// console.log("useLState name state", name, state);
	useEffect(() => {
		localStorage.setItem(localName, JSON.stringify(state));
	}, [state]);
	return [state, setState];
};
export default useLocState;

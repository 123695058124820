import React, { useState, useEffect, useRef } from "react";
import { Button, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./index.css";

interface Props {
	setUser: (user: User) => void;
}

const Authorization = (props: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const form = useRef(null);
	const onSubmit = (event: any) => {
		event.preventDefault();
		const data: any = JSON.stringify(
			Object.fromEntries(new FormData(event.target))
		);
		console.log(data);
		fetch(`https://admin.magday.ru/api/manager/`, {
			method: "post",
			body: data,
		})
			.then((res) => res.json())
			.then((res) => {
				console.log("Authorization res", res);
				if (res.success) {
					props.setUser(res.user);
				} else {
					alert(res.error);
				}
				setIsLoading(false);
			});
	};
	return (
		<section className="Authorization">
			<form onSubmit={onSubmit} ref={form}>
				<Input
					name="login"
					placeholder="email"
					prefix={<UserOutlined />}
				/>
				<Input.Password
					name="password"
					placeholder="pass"
					prefix={<LockOutlined />}
				/>
				<Button type="primary" htmlType="submit" loading={isLoading}>
					Sign in
				</Button>
			</form>
		</section>
	);
};

export default Authorization;

import { useState, useEffect, useRef } from "react";
import PageOrders from "./PageOrders";
import moment, { Moment } from "moment";
import useLocState from "./../../hooks/useLocState";

// const tickAudio =  require('./sounds/when.mp3');
// const tickAudio =  require('./sounds/Button_alert_tone.mp3');
// import { setuid } from "process";

import UIfx from "uifx";
// @ts-ignore
import tickAudio from "./../../sounds/short_notification.mp3";
// const tickAudio =  require('./sounds/intuition.mp3');
const tick = new UIfx(tickAudio);
const newOrderNotification = () => {
	tick.play();
};

interface Props {
	request: (data: any) => Promise<any>;
}

const Container = (props: Props) => {
	const [orders, setOrders] = useState<Orders>([]);
	const [totalOrders, setTotalOrders] = useState<number>(0);
	const [activeOrderId, setActiveOrderId] = useState<number>();

	const [totalMoney, setTotalMoney] = useState<number | false>(false);
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useLocState<number>(0, "orders_itemsPerPage");
	const [itemsPerPage, setItemsPerPage] = useLocState<number>(
		10,
		"orders_itemsPerPage"
	);
	const [customers, setCustomers] = useState<Array<any>>([]);
	const lastOrderId = useRef<number | null>(null);

	// filters
	const [userId, setUserId] = useLocState<number | null | number[]>(
		null,
		"orders_userId"
	);
	// const [excludeUser, setExcludeUser] = useState<number | null>(null);
	const [excludeUser, setExcludeUser] = useLocState<number[] | null>(
		null,
		"exclwudeUser"
	);
	// const [date, setDate] = useLocState<Orderdate>(
	// 	[moment().format("YYYY/MM/DD"), moment().format("YYYY/MM/DD")],
	// 	"date"
	// );
	const [date, setDate] = useState<Orderdate>([
		moment().format("YYYY/MM/DD"),
		moment().format("YYYY/MM/DD"),
	]);

	const fetchOrders = async (page: number, itemsPerPage: number) => {
		const req: OrdersGet = {
			action: "getOrders",
			activeOrderId: activeOrderId,
			page: page,
			itemsPerPage: itemsPerPage,
			userId: userId,
			excludeUser,
			date: date,
		};
		return await props.request(req).then((res: OrdersRes) => {
			console.log("fetchOrders res", res);
			if (res) {
				if (res.customers?.length !== customers.length) {
					setCustomers(res.customers);
				}
				setTotalOrders(res.totalOrders);
				//
				req.getTotalMoney = true;
				props.request(req).then((res: OrdersRes) => {
					// console.log("getTotalMoney", res.totalMoney);
					if (res && res.totalMoney) {
						setTotalMoney(res.totalMoney);
					}
				});
			}
			return [res.orders, res.lastOrderId] as [Orders, number];
		});
	};
	const checkOrders = async () => {
		const [_orders, _lastOrderId]: [Orders, number] = await fetchOrders(
			page,
			itemsPerPage
		);
		if (_orders.length === 0 && page !== 1) {
			setPage(1);
		}
		// console.log("checkOrders _totalOrders", _totalOrders);
		if (orders.length === 0) {
			return _orders;
		} else {
			//is heve new orders
			if (_orders.length > 0) {
				if (lastOrderId.current === null) {
					lastOrderId.current = _orders[0].id;
				} else {
					if (_orders[0].id !== lastOrderId.current) {
						newOrderNotification();
						lastOrderId.current = _orders[0].id;
					}
				}
			}
		}
		return _orders;
	};
	const refresheOrders = async () => {
		const _orders = await checkOrders();
		// console.log("refresheOrders _orders , orders", _orders.length, orders);
		if (_orders) {
			// console.log("refresheOrders setOrders orders", _orders.length);
			setOrders([..._orders]);
			setIsLoading(false);
		}
	};
	useEffect(() => {
		refresheOrders();
	}, []);
	useEffect(() => {
		setIsLoading(true);
		lastOrderId.current = null;
		setTotalMoney(false);
		refresheOrders();
	}, [page, itemsPerPage, userId, date, excludeUser]);

	useEffect(() => {
		const interval = setInterval(() => {
			// console.log("setInterval orders", orders);
			refresheOrders();
		}, 7000);
		return () => clearInterval(interval);
	}, [orders]);
	return (
		<PageOrders
			request={props.request}
			activeOrderId={activeOrderId}
			setActiveOrderId={setActiveOrderId}
			totalMoney={totalMoney}
			userId={userId}
			setExcludeUser={setExcludeUser}
			setUserId={setUserId}
			orders={orders}
			totalOrders={totalOrders}
			isLoading={isLoading}
			page={page}
			setPage={setPage}
			itemsPerPage={itemsPerPage}
			setItemsPerPage={setItemsPerPage}
			customers={customers}
			setDate={setDate}
			date={date}
			excludeUser={excludeUser}
		/>
	);
};

export default Container;

import React, { useState, useEffect } from "react";
import Product from "./Product";

interface Props {
	products: Products;
	stats: { [key: number]: Stats } | null;
	request: (data: any) => Promise<any>;
}

const Products = (props: Props) => {
	console.log("Products stats", props.stats);
	return (
		<section className="ProductsStats">
			{/* <div className=""> */}
			<div className="ListOfProducts">
				<div className="ProductsStatsTitles">
					<div className="img-wrap"></div>
					<span className="coll name">название</span>
					<span className="coll">цена за кг</span>
					<span className="coll">цена конкурента</span>
					<span className="coll">произведенно в этом месяце</span>
					<span className="coll">продано в этом месяце</span>
					<span className="coll">разница на сейчас</span>
					<span className="coll">запасы</span>
					{/*  */}
					<span className="coll">произведенно в прошлом месяце</span>
					<span className="coll">продано в прошлом месяце</span>
					<span className="coll">
						разница на конец прошлого месяца
					</span>
				</div>
				{props.products.map((_product, index) => {
					const stats = props?.stats?.[_product.id];

					return (
						<Product
							key={index}
							product={_product}
							stats={stats || null}
							request={props.request}
						/>
					);
				})}
			</div>
			{/* </div> */}
		</section>
	);
};

export default Products;

import React, { useState } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import {
    SearchOutlined,
  } from '@ant-design/icons';

interface Props {
    request: (data: any) => Promise<any>;
    onSelect: (id:number) => void;
}

const searchResult = async (request: any, query: string) => {
	const req: OrdersGet = {
		action: "getOrders",
		search: query,
		// activeOrderId: activeOrderId,
		// page: page,
		// itemsPerPage: itemsPerPage,
		// userId: userId,
		// excludeUser,
		// date: date,
	};
	const orders = await request(req).then((res: OrdersRes) => {
		console.log("search res", res);
		if (res) {
		}
		return res.orders as Orders;
	});

	return orders.map((order: Order, index: number) => {
		return {
			value: order.id,
			label: (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<span>ID {order.id} </span>
					<span style={{ display: "flex", flexDirection: "column" }}>
						<span>{order.email}</span> <span>{order.phone}</span>{" "}
					</span>
					<span>
						{new Intl.NumberFormat("ru-RU", {
							style: "currency",
							currency: "RUB",
						})
							.format(order.total)
							.replace(/\D00(?=\D*$)/, "")}{" "}
					</span>
				</div>
			),
		};
	});
};

const Search = (props: Props) => {
	const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

	const handleSearch = async (value: string) => {
		if (value.length > 3) {
			const items = await searchResult(props.request, value);
			setOptions(items ? items : []);
		}
	};

	const onSelect = (value: string) => {
        console.log("onSelect", value);
        props.onSelect(parseInt(value))
	};

	return (
        <div style={{paddingTop: "30px"}}>
		<AutoComplete
			dropdownMatchSelectWidth={330}
			style={{ width: 300 }}
			options={options}
			onSelect={onSelect}
			onSearch={handleSearch}
		>
			<Input placeholder="search by id , email or phone" prefix={<SearchOutlined />} allowClear  />
		</AutoComplete>
        </div>
	);
};

export default Search;

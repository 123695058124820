import { useState, useEffect, useCallback } from "react";
import { Divider, Form, InputNumber, Button } from "antd";
import Products from "./Products";
import useLocState from "./../../hooks/useLocState";
import SettingsItem from "./../PageSettings/SettingsItem";
import { formatPrice } from "./../../tools/formatPrice";
import { InputSearchFilter } from "./../InputSearch/InputSearchFilter";

interface Props {
	id: number;
	isFullscreen: boolean;
	isBuyerView: boolean;
	title?: string;
	products: Products;
	isProductionView?: boolean;
}

const Category = (props: Props) => {
	const [itemsPerList, setItemsPerList] = useLocState<number>(
		12,
		`page_orders__cat${props.id}_itemsPerList`
	);
	const [numberOfLists, setNumberOfLists] = useLocState<number>(
		2,
		`page_orders__cat${props.id}_numberOfLists`
	);
	const [productionFilterCurrent, setProductionFilterCurrent] = useState<
		string | null
	>(null);
	// console.log("products", products);
	const totalPrice = props.products.reduce((sum, item) => {
		if (typeof item.stock === "number" && item.stock > 0) {
			return sum + item.price * item.stock;
		} else {
			return sum + item.price;
		}
	}, 0);

	const productionFilterItems = [
		...new Set(props.products.map((x) => x.production)),
	];
	const [products, setProducts] = useState(props.products);
	const [productFS, setProductsFS] = useState(props.products);
	const [query, setQuery] = useState<null | string>(null);
	useEffect(() => {
		if (productionFilterCurrent) {
			setProducts(
				props.products.filter(
					(x) => x.production === productionFilterCurrent
				)
			);
		}
	}, [productionFilterCurrent]);

	const _products = query?.length
		? productFS?.length
			? productFS
			: []
		: products;

	console.log("_products", _products.length, props.products.length);
	return (
		// <section className="sct-Products">
		<>
			<article key={"titles"} className="Category titles">
				<div className="Category--top">
					<div className="img-wrap"></div>
					<div className="wrap">
						<span className="">
							<Divider orientation="left" type="horizontal">
								<span
									style={{
										color:
											props.id === 0 ? "red" : "inherit",
									}}
								>
									{props.title || "-"}
								</span>
							</Divider>
						</span>
						{!props.isBuyerView && (
							<span className="Category--top-totalPrice">
								<Divider orientation="left" type="horizontal">
									{formatPrice(totalPrice)}
								</Divider>
							</span>
						)}
					</div>
				</div>
				{props.isFullscreen || props.isBuyerView ? null : (
					<div className="Category--setting">
						<SettingsItem
							content={
								<>
									<Form.Item label="Кол-во товаров в колонке">
										<InputNumber
											value={itemsPerList}
											onChange={(value) => {
												setItemsPerList(
													parseInt(value + "")
												);
											}}
										/>
									</Form.Item>
									<Form.Item label="Кол-во колонок">
										<InputNumber
											value={numberOfLists}
											onChange={(value) => {
												setNumberOfLists(
													parseInt(value + "")
												);
											}}
										/>
									</Form.Item>
									<p>
										Всего товаров на странице:{" "}
										{numberOfLists * itemsPerList} /{" "}
										{props.products.length}
									</p>
								</>
							}
						/>
					</div>
				)}
				<div className="production-filter">
					<span className="title">Цех: </span>
					{productionFilterItems.map((x) => {
						return (
							<Button
								onClick={() => {
									setProductionFilterCurrent(x);
								}}
								key={x}
								type={
									productionFilterCurrent === x
										? "primary"
										: "default"
								}
							>
								{x}
							</Button>
						);
					})}
				</div>
				<div style={{ maxWidth: "300px", marginBottom: "10px" }}>
					<InputSearchFilter
						products={products}
						setProducts={setProductsFS}
						setQuery={setQuery}
					/>
					<br />
				</div>
				<Products
					isProductionView={props.isProductionView}
					isBuyerView={props.isBuyerView}
					numberOfLists={numberOfLists}
					itemsPerList={itemsPerList}
					products={_products}
					query={query}
				/>
			</article>
		</>
		// </section>
	);
};

export default Category;

import React, { useState, useEffect } from "react";
import { SelectProps } from "antd/es/select";
import { Input, AutoComplete } from "antd";

interface Props {
	setProducts: any;
	products: Product[];
	setQuery: (x: string | null) => void;
}

export const InputSearchFilter = (props: Props) => {
	const [text, setText] = useState("");
	const onChange = (x: any) => {
		setText(x.target.value);
	};

	useEffect(() => {
		const timer = setTimeout(async () => {
			const products = props.products.filter((x) =>
				x.name.toLowerCase().includes(text.toLowerCase())
			);
			props.setQuery(text);
			props.setProducts(products.length ? products : null);
		}, 500);
		return () => {
			clearTimeout(timer);
		};
	}, [text, props.products]);

	return (
		<Input.Search
			size="middle"
			placeholder="search product"
			enterButton
			onChange={onChange}
		/>
	);
};

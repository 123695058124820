import { useState, useEffect } from "react";
import Product from "./Product";

interface Props {
	numberOfLists?: number;
	itemsPerList?: number;
	products: Products;
	isBuyerView: boolean;
	isProductionView?: boolean;
	query?: string | null;
}

const Products = (props: Props) => {
	const [focusedId, setFocusedId] = useState(0);

	const [products, setProducts] = useState<Product[]>(props.products);
	useEffect(() => {
		if (props.numberOfLists && props.itemsPerList) {
			setProducts(
				props.products.slice(
					0,
					props.numberOfLists * props.itemsPerList
				)
			);
		} else {
			setProducts(props.products);
		}
	}, [props.numberOfLists, props.itemsPerList, props.products]);
	let style: React.CSSProperties = {};
	if (props.numberOfLists) {
		style.gridTemplateColumns = `repeat(${props.numberOfLists}, 1fr)`;
		if (!props.itemsPerList) {
			style.gridAutoFlow = "row";
		}
		// props.products.slice( 0 , props.numberOfLists * props.itemsPerList)
		// console.log(props.products.length);
	}
	if (props.itemsPerList) {
		style.gridTemplateRows = `repeat(${props.itemsPerList}, 1fr)`;
	}
	if (!props.numberOfLists) {
		style.gridTemplateColumns = `auto`;
	}
	if (!props.itemsPerList) {
		style.gridTemplateRows = `auto`;
	}
	if (!props.numberOfLists && !props.itemsPerList) {
		style.display = "flex";
		style.flexWrap = "wrap";
	}

	const [productionData, setProductionData] = useState<ProductionData[]>([]);
	useEffect(() => {
		if (props.isProductionView) {
			fetch(`https://admin.magday.ru/api/manager/production.php`, {
				method: "POST",
				body: JSON.stringify({
					action: "get",
					filter: { fail: 0, today: true, confirmed: "ignore" },
					key: "AShgfnhfr1431nGvapgx",
				}),
			})
				.then((res) => res.json())
				.then((res) => {
					console.log("Products fetch production res", res);
					if (res.items && res.success) {
						setProductionData(res.items);
						// console.log("Products fetch production res", res);
					}
				});
		}
	}, []);

	return (
		<section className="Products">
			<div className="ListOfProducts" style={style}>
				{products.map((_product, index) => {
					const _productionData = productionData.filter(
						(x) => x.productId === _product.id
					);
					return (
						<Product
							isProductionView={props.isProductionView}
							key={index}
							product={_product}
							isBuyerView={props.isBuyerView}
							setFocusedId={setFocusedId}
							focusedId={focusedId}
							productionData={_productionData}
							query={props.query}
						/>
					);
				})}
			</div>
		</section>
	);
};

export default Products;

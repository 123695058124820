import React, { useState } from "react";
import "./index.css";
import SettingsItem from "./SettingsItem";

interface Props {
	settingsItems: SettingsItems;
}

const PageSettings = (props: Props) => {
	// console.log("PageSettings props" ,props )
	return (
		<section className="PageSettings">
			{props.settingsItems.map((item, index) => {
				return <SettingsItem key={index} {...item} />;
			})}
		</section>
	);
};

export default PageSettings;

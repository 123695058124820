import React from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import { ReactComponent as IconFood } from "./icons/food.svg";
import { ReactComponent as IconOrder } from "./icons/orders.svg";
import { ReactComponent as IconKnife } from "./icons/knife.svg";
import { PieChartOutlined } from "@ant-design/icons";
import Avatar from "./../User/Avatar";
import json from "./../../../package.json";
interface Props {
	user: User;
}
const Nav = (props: Props) => {
	return (
		<nav>
			<Avatar user={props.user} />
			<ul>
				<li>
					<NavLink end to="/orders">
						<div className="img-wrap stroke">
							<IconOrder />
						</div>
						Orders
					</NavLink>
				</li>
				<li>
					<NavLink end to="/products">
						<div className="img-wrap IconFood">
							<IconFood />
						</div>
						Products
					</NavLink>
				</li>
				<li>
					<NavLink end to="/productsStats">
						<div className="img-wrap IconFood">
							<PieChartOutlined />
						</div>
						Stats
					</NavLink>
				</li>
				<li>
					<NavLink end to="/production">
						<div className="img-wrap IconFood IconKnife">
							<IconKnife />
						</div>
						Production
					</NavLink>
				</li>
			</ul>
			<p className="version">version: {json.version}</p>
		</nav>
	);
};

export default Nav;

import { useState, useEffect } from "react";
import { Input, InputNumber, Button } from "antd";
import { Loader } from "./../Loader/Loader";
interface Props {
	id: number;
	isFocused: boolean;
	setFocusedId?: any;
	productionData?: ProductionData[];
	setProductionData?: (x: ProductionData[]) => void;
}

// let _addComa = (value: string | number) => {
// 	if (typeof value === "number") value = value + "";
// 	if (!value?.length) return "";
// 	let res = "";
// 	if (value.length > 3) {
// 		let index = 0;
// 		for (let l of value) {
// 			if (
// 				index === value.length - 3 ||
// 				(index === value.length - 6 && value.length > 6)
// 			) {
// 				res += ",";
// 			}
// 			if (parseInt(l, 10)) {
// 				res += l;
// 			}
// 			index++;
// 		}
// 		return res;
// 	} else {
// 		return value;
// 	}
// };

const confirmTest = async () => {
	const _res = await fetch(
		`https://admin.magday.ru/api/manager/production.php`,
		{
			method: "POST",
			body: JSON.stringify({
				action: "confirm",
				key: "AShgfnhfr1431nGvapgx",
				ids: [14, 12],
			}),
		}
	).then((res) => res.json());
	console.log("confirmTest res", _res);
};

const clearString = (value: string) => {
	if (typeof value === "number") value = value + "";
	if (!value?.length) return "";
	let res = "";
	for (let l of value) {
		if (parseInt(l, 10) || l === "," || l === "." || l === "0") {
			res += l;
		}
	}
	return res;
};
let convertToGramms = (value: string | number): number => {
	if (typeof value === "number") value = value + "";
	const isFloat = value.includes(",") || value.includes(".");
	if (isFloat) {
		const valueArr = value.includes(",")
			? value.split(",")
			: value.split(".");
		let deimals = "";
		for (let i = 0; i < 3; i++) {
			deimals += valueArr[1][i] || "0";
		}
		return parseInt(valueArr[0] + deimals);
	} else {
		return parseInt(value + "000");
	}
};

export const AddProduction = (props: Props) => {
	const [valueString, setValueString] = useState("");
	const [value, setValue] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [productionData, setProductionData] = useState<ProductionData | null>(
		props.productionData?.sort((a, b) => b.id - a.id)?.[0] || null
	);
	const onChange = (_value: any) => {
		const value = _value.target.value;
		const _val = clearString(value);
		// setValue(val);
		setValueString(_val);
		// props.onChangeProduct(item.product_id, item.weekIndex, val);
	};
	useEffect(() => {
		const val = convertToGramms(valueString);
		// console.log("val", val);
		setValue(val);
	}, [valueString]);
	if (!props.isFocused) {
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<span style={{ fontSize: "10px", width: "60px" }}>
					{productionData ? productionData.date : ""}
				</span>
				<Button
					style={{
						marginLeft: "auto",
						display: "block",
						minWidth: "50px",
					}}
				>
					+
				</Button>
			</div>
		);
	}
	// console.log("AddProduction props.productionData");
	const handleFocus = (event: any) => event.target.select();
	const ok = () => {
		sendProduction(props.id, value);
	};
	const onSubmit = (event: any) => {
		event.preventDefault();
		ok();
	};

	const sendProduction = async (id: number, value: number) => {
		console.log("sendProduction id", id, value);
		// await confirmTest();
		setIsLoading(true);
		const res = await fetch(
			`https://admin.magday.ru/api/manager/production.php`,
			{
				method: "POST",
				body: JSON.stringify({
					action: "set",
					key: "AShgfnhfr1431nGvapgx",
					item: {
						productId: id,
						weight: value,
					},
				}),
			}
		)
			.then((res) => res.json())
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
		// const __res = await fetch(
		// 	`https://admin.magday.ru/api/manager/production.php`,
		// 	{
		// 		method: "POST",
		// 		body: JSON.stringify({
		// 			action: "get",
		// 			key: "AShgfnhfr1431nGvapgx",
		// 		}),
		// 	}
		// ).then((res) => res.json());
		if (res.item && res.success) {
			setProductionData(res.item);
			if (props.setProductionData) {
				const items = props.productionData || [];
				props.setProductionData([...items, res.item]);
			}
		}
		props.setFocusedId(0);
		setIsLoading(false);
		console.log("sendProduction res", res);
		// console.log("sendProduction res", __res);
	};

	return (
		<form style={{ display: "flex", gap: 10 }} onSubmit={onSubmit}>
			<Input
				onFocus={handleFocus}
				autoFocus={true}
				// min={0}
				value={valueString}
				placeholder={"0,000"}
				// defaultValue={0}

				onChange={onChange}
				inputMode={"numeric"}
				style={{ minWidth: "60px" }}
			/>
			<span style={{ display: "flex" }}>
				<span> Кг.</span> | <span> Шт.</span>
			</span>
			{isLoading ? (
				<div style={{ width: "30px", height: "15px" }}>
					<Loader />
				</div>
			) : (
				<Button
					style={{ marginLeft: "auto", display: "block" }}
					onClick={ok}
					type="primary"
				>
					OK
				</Button>
			)}
		</form>
	);
};

import React, { useState, useEffect } from "react";

import "./index.css";

interface PropsItem {
	product: Product;
	stats: Stats | null;
	request: (data: any) => Promise<any>;
}

const Product = (props: PropsItem) => {
	const [price, setPrice] = useState(props.stats?.price || 0);
	const product = props.product;
	if (product.id === 3335) {
		console.log("Product stats", props.stats);
	}
	// props.stats;

	const onChange = (event: any) => {
		setPrice(event.target.value);
	};
	useEffect(() => {
		if (price && price !== props.stats?.price) {
			props.request({
				action: "updateProductInfo",
				id: product.id,
				price: price,
			});
		}
	}, [price]);

	useEffect(() => {
		if (props.stats?.price) {
			setPrice(props.stats.price);
		}
	}, [props.stats]);

	return (
		<article key={"art_" + product.id} className="ProductsStats--Product">
			<div className="img-wrap">
				<img
					src={
						product.img ||
						"https://admin.magday.ru/wp-content/uploads/2018/08/img_no.png"
					}
					alt={product.name}
				/>
			</div>
			<div className="wrap">
				<h2 className="name coll coll-1">{product.name}</h2>
				<span className="coll ">{product.price} ₽</span>
				<span className="coll ">
					{/* TODO price*/}
					<input type="number" value={price} onChange={onChange} />
				</span>
				{/* stock */}
				<span className="coll ">
					{props.stats?.stockPlus && props.stats.stockPlus.toFixed(1)}{" "}
					кг
				</span>
				<span className="coll ">
					{props.stats?.stockMinus &&
						(props.stats?.stockMinus).toFixed(1)}{" "}
					кг
				</span>
				<span className="coll ">
					{props.stats?.stockPlus &&
						props.stats?.stockMinus &&
						(
							props.stats?.stockPlus - props.stats?.stockMinus
						).toFixed(1)}{" "}
					кг
				</span>
				<span className="coll ">
					{typeof props.product.stock === "number" &&
						props.product.stock.toFixed(1) + " кг"}
				</span>

				{/* stock prev */}
				<span className="coll ">
					{props.stats?.stockPlusPrev &&
						props.stats.stockPlusPrev.toFixed(1)}{" "}
					кг
				</span>
				<span className="coll ">
					{props.stats?.stockMinusPrev &&
						(props.stats?.stockMinusPrev).toFixed(1)}{" "}
					кг
				</span>
				<span className="coll ">
					{props.stats?.stockPlusPrev &&
						props.stats?.stockMinusPrev &&
						(
							props.stats?.stockPlusPrev -
							props.stats?.stockMinusPrev
						).toFixed(1)}{" "}
					кг
				</span>
				{/* end stock */}
				{/* <span className="coll ">
					{typeof product.stock === "number" ? (
						<span className="color-green">
							{product.stock.toFixed(1)} кг
						</span>
					) : (
						"???"
					)}
				</span> */}
				{/* <span className="stock coll coll-3">
					{product.stock === true ? (
						<span className="color-green">В наличии </span>
					) : null}
					{product.stock === false ? "Отсутствует" : null}
					{typeof product.stock === "number" ? (
						<span className="color-green">
							{product.stock.toFixed(1)} кг
						</span>
					) : null}
				</span> */}
			</div>
		</article>
	);
};

export default Product;

import "./index.css";
import { useState } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
interface Props {
	productionData: ProductionData[] | null;
	setProductionData: (x: ProductionData[]) => void;
}
export const ProductionList = (props: Props) => {
	const [hide, setHide] = useState<null | number>(null);

	if (!props.productionData?.length) return null;
	const hideThenRemove = (id: number) => {
		setHide(id);
		setTimeout(() => {
			const items = props.productionData || [];
			props.setProductionData([...items.filter((x) => x.id !== id)]);
		}, 400);
	};
	const remove = (id: number) => {
		return async () => {
			const res = await fetch(
				`https://admin.magday.ru/api/manager/production.php`,
				{
					method: "POST",
					body: JSON.stringify({
						action: "update",
						key: "AShgfnhfr1431nGvapgx",
						item: {
							productId: id,
							fail: 1,
						},
					}),
				}
			)
				.then((res) => res.json())
				.catch((err) => {
					alert("err");
				});
			console.log("update res", res);
			if (res.success) {
				hideThenRemove(id);
			}
		};
	};
	return (
		<ul className="ProductionList">
			{props.productionData?.map((x) => {
				return (
					<li key={x.id} className={hide === x.id ? "hide" : ""}>
						<Button htmlType="button" onClick={remove(x.id)}>
							<CloseOutlined />
						</Button>
						<span className="cell">
							{x.weight / 1000} {x.weightType}.
						</span>
						<span className="cell">{x.date}.</span>
					</li>
				);
			})}
		</ul>
	);
};

import { useState, useEffect } from "react";
import { Button, Form, InputNumber, Skeleton, Switch } from "antd";
import {
	SettingOutlined,
	FilterOutlined,
	BorderInnerOutlined,
} from "@ant-design/icons";
import Category from "./Category";
import Filter from "./Filter";
import useLocState from "./../../hooks/useLocState";

interface Props {
	isFullscreen: boolean;
	request: (data: any) => Promise<any>;
	setSettingsItems: (x: SettingsItems) => void;
}

const Container = (props: Props) => {
	// const [products, setProducts] = useState<Products>([]);
	const [products, setProducts] = useState<Product[]>([]);
	const [allProductsCount, setAllProductsCount] = useState(0);

	const [isBuyerView, setIsBuyerView] = useLocState<boolean>(
		false,
		"page_orders__isBuyerView"
	);
	const [categories, setCategories] = useState<Categories>([]);
	const [categoriesState, setCategoriesState] = useState({});
	useEffect(() => {
		// console.log(`categoriesState`, categoriesState);
	}, [categoriesState]);

	useEffect(() => {
		return () => {
			props.setSettingsItems([]);
		};
	}, []);

	useEffect(() => {
		props
			.request({
				action: "getProducts",
				postsPerPage: -1,
				// postsPerPage: itemsPerList * numberOfLists,
				pageNumber: 1,
				categories: categoriesState,
			})
			.then((res) => {
				console.log("getProducts res", res);
				// setProducts(res.products);
				// const products = [];
				// console.log()

				setProducts(res.products);
				setAllProductsCount(res.allProductsCount);
				setCategories(res.categories);
			});
		renderSettings();
	}, [categoriesState]);
	useEffect(() => {
		console.log("useEffect categories", categories);
		renderSettings();
	}, [categories]);

	const renderSettings = () => {
		// props.setSettingsItems([])
		const SContent = (
			<div>
				<Form.Item label="Вид для покупателей">
					<Switch
						defaultChecked={isBuyerView}
						onChange={(value) => {
							setIsBuyerView(value);
						}}
					/>
				</Form.Item>

				<div>
					{/* Всего товаров на странице: {numberOfLists * itemsPerList} /{" "} */}
					{allProductsCount}
				</div>
			</div>
		);

		props.setSettingsItems([
			{
				button: (
					<Button ghost>
						<SettingOutlined style={{ fontSize: "20px" }} />
					</Button>
				),
				content: SContent,
			},
			{
				button: (
					<Button ghost>
						<FilterOutlined style={{ fontSize: "20px" }} />
					</Button>
				),
				content: (
					<Filter
						categories={categories}
						setCategoriesState={setCategoriesState}
						categoriesState={categoriesState}
					/>
				),
			},
		]);
	};
	if (!products) return <Skeleton />;
	if (isBuyerView) {
		return (
			<Category
				id={1}
				isFullscreen={props.isFullscreen}
				isBuyerView={isBuyerView}
				products={products}
			/>
		);
	} else {
		return (
			<main className="page-products">
				<Category
					id={0}
					isFullscreen={props.isFullscreen}
					isBuyerView={isBuyerView}
					products={products.filter((item) => item.required === true)}
					title="ОБЯЗАТЕЛЬНЫЕ ТОВАРЫ В НАЛИЧИИ!!!! ВСЕГДА, ДАЖЕ В ВЫХОДНЫЕ ДНИ"
				/>
				<Category
					id={2}
					isFullscreen={props.isFullscreen}
					isBuyerView={isBuyerView}
					products={products.filter((item) => item.required !== true)}
				/>
			</main>
		);
	}
};

export default Container;

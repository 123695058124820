import { useState, useEffect } from "react";
import { Button, Form, InputNumber, Skeleton, Switch } from "antd";
import {
	SettingOutlined,
	FilterOutlined,
	BorderInnerOutlined,
} from "@ant-design/icons";
import Category from "./../PageProducts/Category";
import Filter from "./../PageProducts/Filter";
import useLocState from "./../../hooks/useLocState";
import "./index.css";

interface Props {
	isFullscreen: boolean;
	request: (data: any) => Promise<any>;
	setSettingsItems: (x: SettingsItems) => void;
}

export const PageProduction = (props: Props) => {
	// const [products, setProducts] = useState<Products>([]);
	const [products, setProducts] = useState<Product[]>([]);
	const [allProductsCount, setAllProductsCount] = useState(0);
	const [categories, setCategories] = useState<Categories>([]);
	// const [productionFilterItems, setProductionFilterItems] = useState([]);
	const [categoriesState, setCategoriesState] = useLocState(
		{},
		"PageProduction_cats_"
	);
	useEffect(() => {
		// console.log(`categoriesState`, categoriesState);
	}, [categoriesState]);

	useEffect(() => {
		return () => {
			props.setSettingsItems([]);
		};
	}, []);

	useEffect(() => {
		props
			.request({
				action: "getProducts",
				postsPerPage: -1,
				// postsPerPage: itemsPerList * numberOfLists,
				pageNumber: 1,
				// categories: categoriesState,
			})
			.then((res) => {
				console.log("PageProduction getProducts res", res);
				// setProducts(res.products);
				// const products = [];
				// console.log()

				setProducts(res.products);
				setAllProductsCount(res.allProductsCount);
				setCategories(res.categories);
			});
		renderSettings();
	}, [categoriesState]);
	useEffect(() => {
		console.log("useEffect categories", categories);
		renderSettings();
	}, [categories]);

	const renderSettings = () => {
		// props.setSettingsItems([])
		// props.setSettingsItems([
		// 	{
		// 		button: (
		// 			<Button ghost>
		// 				<FilterOutlined style={{ fontSize: "20px" }} />
		// 			</Button>
		// 		),
		// 		content: (
		// 			<Filter
		// 				categories={categories}
		// 				setCategoriesState={setCategoriesState}
		// 				categoriesState={categoriesState}
		// 			/>
		// 		),
		// 	},
		// ]);
	};
	if (!products) return <Skeleton />;

	return (
		<Category
			id={1}
			isBuyerView={false}
			isProductionView={true}
			isFullscreen={props.isFullscreen}
			products={products}
		/>
	);
};

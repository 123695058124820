import React from "react";
import { Button } from "antd";
interface Props {
	user: User;
}

const PageUser = (props: Props) => {
	const user = Object.assign({}, props.user);
    const signOut = ()=>{
        localStorage.removeItem("mr_user");
        window.location.reload();
    }
	return (
		<div className="PageUser">
			<ul>
				{Object.keys(user).map((key: any) =>
					key !== "password" ? (
						<li key={key}>
							<span>{key}: </span>
							<span>
								{
									//@ts-ignore
									user[key]
								}
							</span>
						</li>
					) : null
				)}
			</ul>
			<Button type="primary" onClick={signOut} >Sign out</Button>
		</div>
	);
};

export default PageUser;

import { Avatar as AntAvatar } from "antd";
import { Link } from "react-router-dom";
import "./index.css";

interface Props {
	user: User;
}

const Avatar = (props: Props) => {
	return (
		<div className="Avatar">
			<Link to="/user">
				<AntAvatar size={{ md: 40, lg: 64, xl: 80, xxl: 100 }}>
					{props.user.login.slice(0, 3)}
				</AntAvatar>
			</Link>
		</div>
	);
};

export default Avatar;

import { useState, useEffect } from "react";
import OrderDatail from "./OrderDetail";
import { Divider, Skeleton } from "antd";
import { useLocation } from "react-router-dom";
interface Props {
	request: (data: any) => Promise<any>;
}

const Container = (props: Props) => {
	const [order, setOrder] = useState<OrderFull | null>(null);
	const [status, setStatus] = useState<OrderStatus | null>(null);
	const { pathname } = useLocation();
	const _orderId = parseInt(pathname.replace("/orders/", ""));

	const orderId = parseInt(_orderId + "");
	useEffect(() => {
		if (order) {
			setStatus(order.status);
		}
	}, [order]);

	useEffect(() => {
		fetchOrder();
	}, [orderId]);
	const fetchOrder = async () => {
		if (order && orderId !== order.id) {
			setOrder(null);
		}
		const req: OrdersGet = {
			action: "getOrders",
			id: orderId,
		};
		props.request(req).then((res: OrderFullRes) => {
			console.log("fetchOrder res", res);
			setOrder(res.orders[0]);
			// return [res.orders, res.lastOrderId] as [Orders, number];
		});
	};
	useEffect(() => {
		const interval = setInterval(() => {
			// console.log("setInterval orders", orders);
			checkOrderStatus();
		}, 7000);
		return () => clearInterval(interval);
	}, []);
	const checkOrderStatus = () => {};
	if (!order || !status)
		return (
			<div>
				<Divider orientation="left">Детали заказа #</Divider>
				<div className="block">
					<Skeleton />
				</div>
			</div>
		);
	return (
		<>
			<OrderDatail
				request={props.request}
				order={order}
				orderStatus={status}
				setOrder={setOrder}
				setStatus={setStatus}
			/>
		</>
	);
};

export default Container;

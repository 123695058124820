import { useState, useEffect } from "react";
import { AddProduction } from "./../PageProduction/AddProduction";
import { ProductionList } from "./../PageProduction/ProductionList";
import Highlighter from "react-highlight-words";

import "./index.css";

interface PropsItem {
	product: Product;
	isBuyerView: boolean;
	isProductionView?: boolean;
	setFocusedId?: any;
	focusedId?: number;
	productionData?: ProductionData[];
	query?: string | null;
}

const Product = (props: PropsItem) => {
	const product = props.product;
	const isProductionView = props.isProductionView || false;
	const isFocused = product.id === props.focusedId;
	const _productionData =
		props.productionData?.filter((x) => x.productId === product.id) || [];

	const [productionData, setProductionData] = useState(_productionData);
	const setFocus = () => {
		if (isProductionView && !isFocused) {
			props.setFocusedId(product.id);
		}
	};
	useEffect(() => {
		const _productionData =
			props.productionData?.filter((x) => x.productId === product.id) ||
			[];
		setProductionData(_productionData);
	}, [props.product]);
	return (
		<>
			<article
				key={"art_" + product.id}
				className="Product"
				onClick={setFocus}
			>
				<div className="wrap">
					<div className="img-wrap">
						<img
							src={
								product.img ||
								"https://admin.magday.ru/wp-content/uploads/2018/08/img_no.png"
							}
							alt={product.name}
						/>
					</div>
					<div className="wrap">
						<h2 className="name coll coll-1">
							<Highlighter
								highlightClassName="highlighter"
								// highlightClassName="YourHighlightClass"
								searchWords={[props.query + ""]}
								autoEscape={true}
								textToHighlight={product.name}
							/>
						</h2>
						{/* <span className="price coll coll-2">{product.price} руб.</span> */}
						{isProductionView ? (
							<AddProduction
								id={product.id}
								isFocused={isFocused}
								setFocusedId={props?.setFocusedId}
								productionData={productionData}
								setProductionData={setProductionData}
							/>
						) : (
							<Stock stock={product.stock} />
						)}
					</div>
				</div>
				{/* {isProductionView ? <div>open</div> : null} */}
				{isProductionView ? (
					<ProductionList
						productionData={productionData || null}
						setProductionData={setProductionData}
					/>
				) : null}
			</article>
		</>
	);
};

export default Product;
const Stock = (props: any) => {
	return (
		<>
			{props.stock === true ? (
				<span className="color-green">В наличии </span>
			) : null}
			{props.stock === false ? "Отсутствует" : null}
			{typeof props.stock === "number" ? (
				<span className="color-green">{props.stock.toFixed(1)} кг</span>
			) : null}
		</>
	);
};

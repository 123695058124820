import React, { useState, useEffect } from "react";
import { Button, Divider, Table, Switch, Form, Select, Radio } from "antd";
import { PrinterFilled } from "@ant-design/icons";
// import OrderItem from "./components/OrderItem/OrderItem";
import Date from "./Date";
import Search from "./Search";
import OrderDetail from "./../OrderDetail/Container";
import { formatPrice } from "./../../tools/formatPrice";

import {
	BrowserRouter as Router,
	Route,
	NavLink,
	useLocation,
	Routes,
	useNavigate,
} from "react-router-dom";

import "antd/dist/antd.css";
import "./index.css";

const { Option } = Select;
const columns = [
	{ title: "id", dataIndex: "id", key: "id" },
	{ title: "date", dataIndex: "date", key: "date" },
	{ title: "total", dataIndex: "total", key: "total" },
	{ title: "email", dataIndex: "email", key: "email" },
	{ title: "phone", dataIndex: "phone", key: "phone" },
];
interface checksInner {
	[key: number]: Orders;
}
interface Props {
	request: (data: any) => Promise<any>;
	activeOrderId: number | undefined;
	setActiveOrderId: (id: number) => void;
	totalMoney: number | false;
	userId: number | null | number[];
	setUserId: (val: number | null | number[]) => void;
	orders: Orders;
	totalOrders: number;
	isLoading: boolean;
	page: number;
	setPage: (val: number) => void;
	itemsPerPage: number;
	setItemsPerPage: (val: number) => void;
	customers: Array<any>;
	setDate: (val: Orderdate) => void;
	date: Orderdate;
	setExcludeUser: (val: number[] | null) => void;
	excludeUser: number[] | null;
}

function PageOrders(props: Props) {
	const [checks, setChecks] = useState<Orders>([]);
	const [checksInner, setChecksInner] = useState<checksInner>({});
	const activeOrderId = props.activeOrderId;
	const setActiveOrderId = props.setActiveOrderId;
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const _orderId = parseInt(pathname.replace("/orders/", ""));
	function openProduct(id: number) {
		// console.log("openProduct", id);
		navigate(`/orders/${id}`);
	}

	const expandedRowRender = (record: Order) => {
		return (
			<Table
				rowKey="innerKey"
				size="small"
				rowClassName={(record) => {
					return record.id === activeOrderId ? "isActive" : "";
				}}
				columns={columns}
				dataSource={record.prevOrders}
				// pagination={["bottomRight"]}
				pagination={{
					total: record.totalOrders, // total count returned from backend
				}}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {
							console.log(
								"setActiveOrderId record.id",
								record.id
							);
							openProduct(record.id);
							setActiveOrderId(record.id);
						},
					};
				}}
				rowSelection={{
					type: "checkbox",
					onChange: (keys, rows) => {
						console.log("keys rows", keys, rows, record);
						const data = checksInner;
						//@ts-ignore
						data[record.id] = keys;
						setChecksInner(Object.assign({}, data));
					},
				}}
				// expandable={{
				// 	expandedRowRender: record => <p style={{ margin: 0 }}></p>,
				// 	rowExpandable: record => true
				//   }}
			/>
		);
	};
	const printChecks = () => {
		console.log("printChecks", checks, checksInner);
		Object.keys(checksInner).forEach((item: any) => {
			//@ts-ignore
			checks.push(...checksInner[item]);
		});
		[...checks].forEach((orderId) => {
			window.open(
				`http://admin.magday.ru/api/manager/check.php?orderId=${orderId}`
			);
		});
	};
	const onFilterUserCnange = (val: number | "all") => {
		val === "all" ? props.setUserId(null) : props.setUserId(val);
	};
	const userFilter = (event: any) => {
		const value = event.target.value;
		if (typeof value === "number") {
			props.setUserId(value);
			props.setExcludeUser(null);
		} else {
			console.log("value", value);
			props.setUserId(null);
			if (value === "online") {
				props.setExcludeUser([19, 24, 25]);
			}
			if (value === "ofline") {
				// props.setExcludeUser([19, 24, 25]);
				props.setUserId([19, 24, 25]);
			}
		}
	};
	return (
		<div className="Page PageOrders">
			{/* <header className="App-header">
				<h1>Управление заказами </h1>
			</header> */}
			<main>
				<section className="sct-orders">
					<Divider orientation="left">
						Заказы ({props.totalOrders})
						{props.totalMoney
							? " на " + formatPrice(props.totalMoney)
							: null}
					</Divider>
					<Table
						loading={props.isLoading}
						rowSelection={{
							type: "checkbox",
							onChange: (keys, rows) => {
								// console.log("keys rows", keys, rows);
								//@ts-ignore
								setChecks([...keys]);
							},
						}}
						pagination={{
							showSizeChanger: true,
							total: props.totalOrders,
							pageSize: props.itemsPerPage,
							onChange: props.setPage,
							onShowSizeChange: (current, size) => {
								// console.log("onShowSizeChange", current , size);
								props.setItemsPerPage(size);
							},
						}}
						rowClassName={(record) => {
							return record.id === activeOrderId
								? "isActive"
								: "";
						}}
						size="small"
						onRow={(record, rowIndex) => {
							return {
								onClick: (event) => {
									openProduct(record.id);
									setActiveOrderId(record.id);
								},
							};
						}}
						rowKey="id"
						className="components-table-demo-nested"
						columns={columns}
						bordered
						expandable={{
							expandedRowRender,
							rowExpandable: (record) =>
								record.prevOrders.length > 0,
						}}
						dataSource={props.orders}
						title={() => (
							<div className="table-controls">
								<Button onClick={printChecks}>
									<PrinterFilled />
									Печатать чеки
								</Button>

								<Radio.Group
									defaultValue="horizontal"
									onChange={userFilter}
								>
									<Radio.Button value={19}>
										Касса1
									</Radio.Button>
									<Radio.Button value={24}>
										Касса2
									</Radio.Button>
									<Radio.Button value={25}>
										Касса3
									</Radio.Button>
									<Radio.Button value={"ofline"}>
										Офлайн
									</Radio.Button>
									<Radio.Button value={"online"}>
										Онлайн
									</Radio.Button>
								</Radio.Group>

								<>
									<Select
										// @ts-ignore
										defaultValue="all"
										showSearch
										disabled={
											props.userId === 19 ||
											props.userId === 24 ||
											props.userId === 25
												? true
												: false
										}
										style={{ minWidth: 130 }}
										placeholder="Select a person"
										optionFilterProp="children"
										onChange={onFilterUserCnange}
										filterOption={(
											input: any,
											option: any
										) =>
											// @ts-ignore
											option.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										}
									>
										<Option key="all" value={"all"}>
											Все
										</Option>
										{props.customers.map((item) => (
											<Option
												key={item.id}
												value={item.id}
											>
												{item.name}
											</Option>
										))}
									</Select>
									<Date
										setDate={props.setDate}
										defaultValue={props.date}
									/>
								</>
							</div>
						)}
					/>
				</section>
			</main>
			<aside>
				<Search request={props.request} onSelect={openProduct} />
				{_orderId ? <OrderDetail request={props.request} /> : null}
				<Routes>
					<Route
						path={`orders/:_orderId`}
						element={<OrderDetail request={props.request} />}
					/>
				</Routes>
				{/* <UserDetail user={activeOrder.user} /> */}
			</aside>
		</div>
	);
}

export default PageOrders;

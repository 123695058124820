import React, { useState, useEffect } from "react";
import { Checkbox, Button } from "antd";
import { CloseSquareOutlined, UndoOutlined } from "@ant-design/icons";

type CategoryState = "checked" | "excluded" | "none";
// interface CategoryState {

// }
interface Props {
	categories: Categories;
	setCategoriesState: any;
	categoriesState: any;
}
const Filter = (props: Props) => {
	// console.log("Filter props.categories", props.categories);

	const onItemChange = (id: number, state: CategoryState) => {
		let obj = Object.assign({}, props.categoriesState);
		obj[id] = state;
		props.setCategoriesState(obj);
	};
	return (
		<ul className="Filter">
			{props.categories.map((cat) => {
				return (
					<Item key={cat.id} category={cat} onChange={onItemChange} />
				);
			})}
		</ul>
	);
};

export default Filter;
interface ItemProps {
	category: Category;
	onChange: (id: number, state: CategoryState) => void;
}

const Item = (props: ItemProps) => {
	const [state, setState] = useState<CategoryState>("none");
	const cat = props.category;
	const onChange = (event: any) => {
		if (event.target.checked) {
			setState("checked");
		} else {
			setState("none");
		}
	};
	const toggleExcluded = (event: any) => {
		if (state !== "excluded") {
			setState("excluded");
		} else {
			setState("none");
		}
	};
	useEffect(() => {
		props.onChange(cat.id, state);
	}, [state]);

	return (
		<li className={state === "excluded" ? "excluded" : ""}>
			<Checkbox
				checked={state === "checked" ? true : false}
				onChange={onChange}
			>
				{cat.name} ({cat.count})
			</Checkbox>
			<Button size="small" onClick={toggleExcluded}>
				{state === "excluded" ? "enable" : "exclude"}
				{state === "excluded" ? (
					<UndoOutlined />
				) : (
					<CloseSquareOutlined />
				)}
			</Button>
		</li>
	);
};

import { useState, useEffect } from "react";
// import { Button, Divider, Table } from "antd";
import Nav from "./components/Nav/Nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageOrders from "./components/PageOrders/Container";
import PageProductsContainer from "./components/PageProducts/Container";
import ProductsStats from "./components/ProductsStats/Container";

import PageUser from "./components/User/PageUser";
import Fullscreen from "./components/Fullscreen/Fullscreen";
import Authorization from "./components/Authorization/Authorization";
import PageSettings from "./components/PageSettings/PageSettings";
// import OrderDetail from "./components/OrderDetail/Container";
import { PageProduction } from "./components/PageProduction/PageProduction";

import "antd/dist/antd.css";

import "./App.css";
function App() {
	const [settingsItems, setSettingsItems] = useState<SettingsItems>([]);
	// @ts-ignore
	const saved_user: User | null = JSON.parse(localStorage.getItem("mr_user"));
	const [user, setUser] = useState<User | null>(saved_user);
	const [isFullscreen, setIsFullscreen] = useState(
		document.fullscreenElement ? true : false
	);
	useEffect(() => {
		localStorage.setItem("mr_user", JSON.stringify(user));
	}, [user]);
	if (!user) {
		return <Authorization setUser={setUser} />;
	}
	const request = async (data: any) => {
		data.login = user.login;
		data.password = user.password;
		return fetch(`https://admin.magday.ru/api/manager/`, {
			method: "post",
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.catch((err) => {
				console.log("error", err);
				// alert(err);
			});
	};
	// const [isMobile, setisMobile] = useState(false);

	return (
		<div className="App">
			{isFullscreen ? null : (
				<PageSettings settingsItems={[...settingsItems]} />
			)}

			<Fullscreen
				isFullscreen={isFullscreen}
				setIsFullscreen={setIsFullscreen}
			/>
			<Router>
				{isFullscreen ? null : <Nav user={user} />}
				<Routes>
					<Route
						path="/products"
						element={
							<PageProductsContainer
								request={request}
								isFullscreen={isFullscreen}
								setSettingsItems={setSettingsItems}
							/>
						}
					/>
					<Route
						path="/productsStats"
						element={
							<ProductsStats
								request={request}
								isFullscreen={isFullscreen}
								setSettingsItems={setSettingsItems}
							/>
						}
					/>

					<Route path="/user" element={<PageUser user={user} />} />
					<Route
						path="/orders/*"
						element={<PageOrders request={request} />}
					/>
					<Route
						path="/production/*"
						element={
							<PageProduction
								request={request}
								isFullscreen={false}
								setSettingsItems={setSettingsItems}
							/>
						}
					/>
					{/* <Route
						path={`orders/:_orderId`}
						element={<OrderDetail request={request} />}
					/> */}
				</Routes>
			</Router>
		</div>
	);
}

export default App;

import React, { useEffect } from "react";
import "./index.css";
// import { FullscreenOutlined } from "@ant-design/icons";

interface Props {
	isFullscreen: boolean;
	setIsFullscreen: any;
}

const Fullscreen = (props: Props) => {
	useEffect(() => {
		document.addEventListener("fullscreenchange", (event) => {
			if (document.fullscreenElement) {
				props.setIsFullscreen(true);
			} else {
				props.setIsFullscreen(false);
			}
		});
	}, []);
	const toggleFullscreen = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else {
			document.documentElement.requestFullscreen
				? document.documentElement.requestFullscreen()
				: // @ts-ignore
				  document.documentElement.webkitRequestFullscreen();
		}
	};
	const className = `Fullscreen ${props.isFullscreen ? "isFullscreen" : ""}`;
	if (props.isFullscreen) return null;
	return <button className={className} onClick={toggleFullscreen}></button>;
};
export default Fullscreen;

import React, { useState, useEffect } from "react";
import { Button, Form, InputNumber, Skeleton, Switch } from "antd";
import {
	SettingOutlined,
	FilterOutlined,
	BorderInnerOutlined,
} from "@ant-design/icons";
import Filter from "./Filter";
import useLocState from "./../../hooks/useLocState";
import Products from "./Products";

interface Props {
	isFullscreen: boolean;
	request: (data: any) => Promise<any>;
	setSettingsItems: (x: SettingsItems) => void;
}

const Container = (props: Props) => {
	// const [products, setProducts] = useState<Products>([]);
	const [products, setProducts] = useState<Product[]>([]);
	const [stats, setStats] = useState<{ [key: number]: Stats } | null>(null);
	const [categories, setCategories] = useState<Categories>([]);
	const [categoriesState, setCategoriesState] = useState({});
	useEffect(() => {
		// console.log(`categoriesState`, categoriesState);
	}, [categoriesState]);

	useEffect(() => {
		return () => {
			props.setSettingsItems([]);
		};
	}, []);

	useEffect(() => {
		props
			.request({
				action: "getProducts",
				postsPerPage: -1,
				// postsPerPage: itemsPerList * numberOfLists,
				pageNumber: 1,
				categories: categoriesState,
			})
			.then((res) => {
				console.log("getProducts res", res);
				setProducts(res.products);
				setCategories(res.categories);

				// stats
				const ids = products.map((prod) => prod.id);
				props
					.request({
						action: "getProductsStats",
						ids: ids,
					})
					.then((res) => {
						setStats(res.stats);
						console.log("getProductStats", res);
					});
			});
		renderSettings();
	}, [categoriesState]);
	useEffect(() => {
		console.log("useEffect categories", categories);
		renderSettings();
	}, [categories]);

	const renderSettings = () => {
		// props.setSettingsItems([])

		props.setSettingsItems([
			{
				button: (
					<Button ghost>
						<FilterOutlined style={{ fontSize: "20px" }} />
					</Button>
				),
				content: (
					<Filter
						categories={categories}
						setCategoriesState={setCategoriesState}
						categoriesState={categoriesState}
					/>
				),
			},
		]);
	};
	if (!products) return <Skeleton />;
	return (
		<main className="page-stats">
			<Products
				products={products}
				stats={stats}
				request={props.request}
			/>
		</main>
	);
};

export default Container;

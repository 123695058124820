import React from "react";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";

const { RangePicker } = DatePicker;
// const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
// const monthFormat = "YYYY/MM";

// const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

// const customFormat = (value: any) => {
// 	return `custom format: ${value.format(dateFormat)}`;
// };

interface Props {
	setDate: (val: Orderdate) => void;
	defaultValue: Orderdate;
}

const Date = (props: Props) => {
	// moment.locale("");
	const disabledDate = (current: Moment) => {
		return current && current > moment().endOf("day");
	};
	const onChange = (value: any, mode: any) => {
		// moment().format(dateFormat)
		props.setDate(mode);
		console.log("Date onChange", value, mode);
	};
	const defaultValue: any = props.defaultValue?.[0]
		? [
				moment(props.defaultValue?.[0], dateFormat),
				moment(props.defaultValue?.[1], dateFormat),
		  ]
		: [moment(), moment()];

	// const defaultValue =[moment(), moment()];

	return (
		<RangePicker
			locale={locale}
			onChange={onChange}
			defaultValue={defaultValue}
			// showToday={true}
			disabledDate={disabledDate}
			format={dateFormat}
		/>
	);
};

export default Date;

import React, { useState, useEffect } from "react";
import { Divider, Select, Button } from "antd";
import Products from "./../Products/Products";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "./index.css";
const { Option } = Select;
interface Props {
	order: OrderFull;
	orderStatus: OrderStatus | null;
	setOrder: any;
	setStatus: any;
	request: (data: any) => Promise<any>;
}
const OrderDatail = (props: Props) => {
	const [prevOrderId, setPrevOrderId] = useState<number | null>(null);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [onChangeAnimation, setOnChangeAnimation] = useState<boolean>(false);
	const navigate = useNavigate();

	function handleChange(value: any) {
		console.log(`selected ${value}`);
		props.setStatus(value);
	}
	useEffect(() => {
		// props.setStatus(props.order.status);
		// console.log("prevOrderId" , prevOrderId , props.order.id , props.orderStatus);
		if (props.order.id === prevOrderId) {
			if (props.order.status !== props.orderStatus) {
				orderStatusIsChangedByUser();
				props.setStatus(props.order.status);
			}
		} else {
			setPrevOrderId(props.order.id);
			props.setOrder(Object.assign({}, props.order));
			props.setStatus(props.order.status);
		}
	}, [props.orderStatus]);

	const orderStatusIsChangedByUser = () => {
		console.log("orderStatusIsChangedByUser");
		setOnChangeAnimation(true);
	};
	useEffect(() => {
		if (onChangeAnimation) {
			const timer = setTimeout(() => {
				setOnChangeAnimation(false);
			}, 10000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [onChangeAnimation]);
	const onChangeProduct = (id: number, weekIndex: number, count: number) => {
		// console.log("onChangeProduct id weekIndex count", id, weekIndex, count);
		if (count > 0) {
			const products = [...props.order.items];
			const index = products.findIndex((item) => {
				return item.product_id === id && item.weekIndex === weekIndex;
			});
			products[index].quantity = count;
			props.order.items = products;
			props.setOrder(Object.assign({}, props.order));
		}
	};
	const addProduct = (value: OrderProduct) => {
		console.log("addProduct", value);
		//@ts-ignore
		props.order.items.push(value);
		props.setOrder(Object.assign({}, props.order));
	};
	const deleteProduct = (id: number, weekIndex: number) => {
		console.log("deleteProduct id weekIndex", id, weekIndex);
		const products = [...props.order.items].filter((item) => {
			return !(item.product_id === id && item.weekIndex === weekIndex);
		});

		props.order.items = products;
		props.setOrder(Object.assign({}, props.order));
	};
	const submit = () => {
		setSubmitLoading(true);
		const req: updateOrder = {
			action: "updateOrder",
			order: props.order,
		};
		props
			.request(req)
			.then((res: any) => {
				console.log("updateOrder res", res);
				props.setOrder(res.order);
				setSubmitLoading(false);
			})
			.catch((err) => {
				console.log("error", err);
				setSubmitLoading(false);
				alert(err);
			});
	};
	const className = `OrderDatail ${
		onChangeAnimation ? " onChangeAnimation" : ""
	}`;
	return (
		<section className={className}>
			<div className="close">
				<Button
					onClick={() => {
						navigate("/orders");
					}}
				>
					<CloseCircleOutlined style={{ color: "white" }} />
				</Button>
			</div>
			<Divider orientation="left">
				Детали заказа # {props.order.id}
			</Divider>
			<div className="block">
				<ul className="block-ul">
					<li className="status">
						<span>Статус</span>
						<span>
							{props.orderStatus && (
								<Select
									defaultValue={props.orderStatus}
									// value={status}
									style={{ width: 120 }}
									onChange={handleChange}
								>
									<Option value="pending">Принят</Option>
									{/* <Option value="processing">
										processing
									</Option> */}
									{/* <Option value="on-hold">on-hold</Option> */}
									<Option value="completed">Выполнен</Option>
									<Option value="cancelled">Отменен</Option>
									{/* <Option value="refunded">refunded</Option> */}
								</Select>
							)}
						</span>
					</li>
					<li>
						<span>Способ оплаты</span>{" "}
						<span>{props.order.payment_method}</span>
					</li>

					<li>
						{/* <span>Состав заказа</span> */}
						{/* <span> */}
						<Products
							products={[...props.order.items]}
							onChangeProduct={onChangeProduct}
							deleteProduct={deleteProduct}
							addProduct={addProduct}
						/>
						{/* </span> */}
					</li>
					<li>
						<span>Адрес</span> <span>{props.order.address}</span>
					</li>
					<li>
						<span>Комментарии</span>{" "}
						<ul className="comments">
							{props.order.comments.map((item, index) => (
								<li>
									<span className="added-by">
										{item.added_by}
									</span>
									<p
										key={index}
										dangerouslySetInnerHTML={{
											__html: item.content,
										}}
									></p>
								</li>
							))}
						</ul>
					</li>

					<li>
						<span>ip</span> <span>{props.order.ip}</span>
					</li>
				</ul>
				<Button
					type="primary"
					// icon={<PoweroffOutlined />}
					loading={submitLoading}
					onClick={submit}
				>
					SAVE
				</Button>
			</div>
		</section>
	);
};

export default OrderDatail;
